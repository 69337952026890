/*
 This module is used to track form submissions by sending information
 to the dataLayer which is picked up by Google Tag Manager, which
 proceeds to forward the event onwards to GA4.
 */

interface RecaptchaCallbackWindow {
  [key: `recaptchaCallbackinput_${number}`]: (tokenResponse: string) => void | undefined;
}
// Add dataLayer as a global intereface
declare global {
  interface Window extends RecaptchaCallbackWindow {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any[];
  }
}

type FormieSubmitEvent = CustomEvent<{
  success: boolean;
  submissionId: number;
  currentPageId: number;
  nextPageId: number | null;
  nextPageIndex: number;
  totalPages: number;
  redirectUrl: string;
  submitActionMessage: string;
  events: Array<{
    event: string;
    data: Record<string, unknown>;
  }>;
  redirectTarget: Window;
}>;

export default function init() {
  // Formie form submission events
  document.querySelectorAll<HTMLFormElement>('.fui-form').forEach((form) => {
    form.addEventListener('onAfterFormieSubmit', (e) => {
      e.preventDefault();
      const response = (e as FormieSubmitEvent).detail;

      if (response.success && !response.nextPageId) {
        const { formHandle } = JSON.parse(form.dataset.fuiForm!);

        // Set tracking object
        const tracking = {
          event: 'form_submission',
          form_handle: formHandle,
        };

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(tracking);
      }
    });
  });

  // Jotform form submission events
  // BUG Jotform's full source code embed seems to have an issue with the reCAPTCHA being initialized after it should be
  // TODO re-enable when Jotform have resolved
  // document.querySelectorAll<HTMLFormElement>('.jotform-form').forEach((form) => {
  //   const recaptchaTag = form.querySelector<HTMLScriptElement>(
  //     '[data-component="recaptcha"][data-callback]',
  //   )!;

  //   const callbackName = recaptchaTag.dataset.callback! as `recaptchaCallbackinput_${number}`;
  //   const inputNumber = callbackName.split('_')[1];

  //   /**
  //    * This function overrides the reCAPTCHA success callback set by Jotform in its embed code,
  //    * replicates its functionality and adds a GTM event.
  //    */
  //   window[callbackName] = function () {
  //     // START Replicate Jotform's recaptchaCallbackinput_* callback
  //     // This has been adapted to vanilla JS from Jotform's code which uses the old prototypejs
  //     const hiddenInput = form.querySelector<HTMLInputElement>(`#input_${inputNumber}`)!;
  //     hiddenInput.value = '1';

  //     const button = form.ownerDocument.createElement('input');
  //     button.style.display = 'none';
  //     button.type = 'submit';
  //     form.appendChild(button).click();
  //     form.removeChild(button);
  //     // END Replicate Jotform's recaptchaCallbackinput_* callback

  //     // START custom GTM event
  //     const formData = new FormData(form);
  //     const quote_type = formData.get('q42_quoteType') as 'Purchase' | 'Sale' | undefined;
  //     const tracking = {
  //       event: 'form_submission',
  //       form_handle: 'conveyancingQuote',
  //       quote_type: quote_type,
  //       ...(quote_type === 'Purchase'
  //         ? {
  //             price: formData.get('q4_purchasePrice'),
  //             num_purchase: formData.get('q10_numBuyers'),
  //             mortgage: formData.get('q5_purchaseMortgage'),
  //             mortgage_lender: formData.get('q6_purchaseLender'),
  //             hold_type: formData.get('q7_purchaseHoldType'),
  //             property_type: formData.get('q8_purchasePropertyType'),
  //             purchase_scheme: formData.get('q11_purchaseScheme'),
  //             purchase_type: formData.get('q12_purchaseType'),
  //             first_time_buyer: formData.get('q13_firstTimeBuyer'),
  //             isa_type: formData.get('q15_isaType'),
  //             is_additional_property: formData.get('q16_isAdditionalProperty'),
  //             has_gifted_deposit: formData.get('q17_hasGiftedDeposit'),
  //           }
  //         : {
  //             price: formData.get('q48_salePrice'),
  //             num_sellers: formData.get('q49_numSellers'),
  //             mortgage: formData.get('q51_saleMortgage'),
  //             mortgage_lender: formData.get('q52_saleLender'),
  //             hold_type: formData.get('q50_salePropertyType'),
  //             property_type: formData.get('q53_salePropertyType'),
  //             sale_type: formData.get('q59_saleType'),
  //             has_help_to_buy: formData.get('q54_hasHelpToBuy'),
  //           }),
  //     };

  //     window.dataLayer = window.dataLayer || [];
  //     window.dataLayer.push(tracking);
  //     // END custom GTM event
  //   };
  // });
}
