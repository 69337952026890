import type { PaginationOptions, SwiperOptions } from 'swiper/types';
import { elementsInViewOnce } from '@helpers/elementsInView';
// import { mediaMatchesOnce } from '@helpers/breakpoints';
import { breakpoints } from '@helpers/breakpoints';

const importDepenciesOnceWhenInView = elementsInViewOnce(() =>
  Promise.all([
    // core version
    import('swiper'),
    // navigation, pagination modules:
    import('swiper/modules'),
    // import Swiper and modules styles\
    import('swiper/css'),
    import('swiper/css/pagination'),
    import('swiper/css/autoplay'),
    import('swiper/css/effect-fade'),
    import('swiper/css/navigation'),
  ]),
);

export default async function init() {
  const swipers = [...document.querySelectorAll<HTMLElement>('.js-swiper')];
  if (!swipers.length) return;
  const [{ default: Swiper }, { Pagination, EffectFade, Navigation, Autoplay }] =
    await importDepenciesOnceWhenInView(swipers);
  // configure Swiper to use modules
  Swiper.use([Pagination, EffectFade, Navigation, Autoplay]);
  for (const element of swipers) {
    // Configure defualt options used by all swipers
    const options: SwiperOptions = {
      speed: 1000,
      slidesPerView: 1,
      spaceBetween: 7, // Each slide should also have 20px padding to account for shadow = 27px
      centeredSlides: true,
      pagination: {
        el: element.querySelector<HTMLElement>('.swiper-pagination'),
        clickable: true,
        /**
         * Make pagination bullets more accessible with aria-label and button instead of span
         */
        renderBullet: (index, className) => {
          return `<button class="${className}" aria-label="Page ${index + 1}"></button>`;
        },
      },
    };

    if (element.classList.contains('reviews-swiper')) {
      const slideCount = element.querySelectorAll('.swiper-slide').length;
      options.loop = false;
      options.slidesPerView = 1;
      options.initialSlide = 0;
      options.spaceBetween = 0;
      options.breakpoints = {
        [breakpoints.md]: {
          slidesPerView: slideCount === 1 ? 1 : 2,
          spaceBetween: 0,
          centeredSlides: false,
        },
        [breakpoints.xl]: {
          slidesPerView: slideCount > 3 ? 3 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
      };
      options.navigation = {
        enabled: true,
        nextEl: element.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl: element.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    } else if (element.classList.contains('accreditations-swiper')) {
      options.slidesPerView = 1;
      options.centeredSlides = false;
      options.spaceBetween = 0;
      options.navigation = {
        enabled: true,
        nextEl:
          element.querySelector<HTMLElement>('.swiper-button-next') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl:
          element.querySelector<HTMLElement>('.swiper-button-prev') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.breakpoints = {
        [breakpoints.sm]: {
          slidesPerView: 3,
          navigation: {
            enabled: true,
          },
        },
        [breakpoints.lg]: {
          slidesPerView: 4,
          navigation: {
            enabled: true,
          },
        },
        [breakpoints.xl]: {
          slidesPerView: 5,
          navigation: {
            enabled: true,
          },
        },
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    } else if (element.classList.contains('testimonials-swiper')) {
      options.loop = false;
      options.autoplay = {
        delay: 8000,
        disableOnInteraction: false,
      };
      options.navigation = {
        enabled: true,
        nextEl:
          element.querySelector<HTMLElement>('.swiper-button-next') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl:
          element.querySelector<HTMLElement>('.swiper-button-prev') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    } else if (element.classList.contains('highlights-swiper')) {
      const images = document.querySelectorAll('.js-img-slide');
      options.loop = false;
      options.autoplay = {
        delay: 8000,
        disableOnInteraction: false,
      };
      options.navigation = {
        enabled: true,
        nextEl:
          element.querySelector<HTMLElement>('.swiper-button-next') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl:
          element.querySelector<HTMLElement>('.swiper-button-prev') ||
          element.parentElement?.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.on = {
        slideChange: () => {
          const currentIndex = swiper.realIndex;
          images.forEach((img, index) => {
            const isCurrent = index === currentIndex;
            img.classList.toggle('hidden', !isCurrent);
            img.classList.toggle('animate-fadeIn', isCurrent);
          });
        },
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    } else if (element.classList.contains('profile-swiper')) {
      const slideCount = element.querySelectorAll('.swiper-slide').length;
      options.loop = false;
      options.slidesPerView = 1;
      options.initialSlide = 0;
      options.spaceBetween = 0;
      options.breakpoints = {
        [breakpoints.sm]: {
          slidesPerView: slideCount > 2 ? 2 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
        [breakpoints.md]: {
          slidesPerView: slideCount > 2 ? 2 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
        [breakpoints.lg]: {
          slidesPerView: slideCount > 3 ? 3 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
        [breakpoints.xl]: {
          slidesPerView: slideCount > 3 ? 4 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
      };
      options.navigation = {
        enabled: true,
        nextEl: element.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl: element.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    } else if (element.classList.contains('article-card-swiper')) {
      const slideCount = element.querySelectorAll('.swiper-slide').length;
      options.loop = false;
      options.slidesPerView = 1;
      options.initialSlide = 0;
      options.spaceBetween = 0;
      options.breakpoints = {
        [breakpoints.md]: {
          slidesPerView: slideCount > 1 ? 2 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
        [breakpoints.xl]: {
          slidesPerView: slideCount > 3 ? 3 : slideCount,
          spaceBetween: 0,
          centeredSlides: false,
        },
      };
      options.navigation = {
        enabled: true,
        nextEl: element.querySelector<HTMLElement>('.swiper-button-next'),
        prevEl: element.querySelector<HTMLElement>('.swiper-button-prev'),
      };
      options.pagination = {
        ...(options.pagination as PaginationOptions),
        dynamicBullets: true,
        dynamicMainBullets: 3,
      };
    }

    const swiper = new Swiper(element, options);
  }
}
