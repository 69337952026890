import type { Directive } from 'vue';

declare global {
  interface HTMLDivElement {
    _clickOutsideEvent?: (event: MouseEvent) => void;
  }
}

const stop = (e: Event) => {
  e.stopPropagation();
};

const clickOutside: Directive<HTMLDivElement, (event: MouseEvent, el: HTMLDivElement) => void> = {
  beforeMount(el, binding) {
    el._clickOutsideEvent = function (event) {
      if (
        el !== event.target &&
        (!(event.target instanceof Element) || !el.contains(event.target))
      ) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener('click', el._clickOutsideEvent);

    if (binding.modifiers.stop) {
      el.addEventListener('click', stop);
    }
  },
  unmounted(el) {
    document.body.removeEventListener('click', el._clickOutsideEvent!);
    el.removeEventListener('click', stop);
    delete el._clickOutsideEvent;
  },
};

export default clickOutside;
