// Modules
import formieCsrf from '@modules/formieCsrf';
import gtm from '@modules/gtm';
import map from '@modules/mapbox';
import swiper from '@modules/swiper';
import accordion from '@modules/accordion';
import video from '@modules/video';
import socialShare from '@modules/socialShare';

export default function init(): void {
  // Modules
  formieCsrf();
  gtm();
  map();
  swiper();
  accordion();
  video();
  socialShare();
}
