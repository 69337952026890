export default function init() {
  const shareButton = document.querySelector<HTMLButtonElement>('.js-social-share-button');
  const shareDialog = document.querySelector<HTMLDivElement>('.js-social-share-dialog');
  const url = encodeURI(window.location.href);

  if (!shareButton || !shareDialog) return;

  const articleTitle = shareDialog.dataset.title || '';
  const copyLink = shareDialog.querySelector<HTMLButtonElement>('.js-copy-link')!;
  const closeButton = shareDialog.querySelector<HTMLButtonElement>('.js-close-button')!;
  const textElement = copyLink.querySelector<HTMLSpanElement>('.js-text-content')!;

  // Toggle share dialog
  shareButton.addEventListener('click', () => {
    if (navigator.share) {
      navigator.share({
        title: articleTitle,
        url: url,
      });
    } else {
      shareButton.setAttribute('aria-expanded', 'true');
      shareDialog.classList.remove('hidden');
      shareDialog.setAttribute('aria-hidden', 'false');
    }
  });

  // Copy link to clipboard
  copyLink.addEventListener('click', () => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        textElement.textContent = 'Link copied!';
      })
      .catch((err) => {
        console.error('Could not copy text: ', err);
      });
  });

  // Close share dialog
  closeButton.addEventListener('click', (e: MouseEvent) => {
    e.preventDefault();
    e.stopImmediatePropagation();
    shareDialog.classList.add('hidden');
    shareDialog.setAttribute('aria-hidden', 'true');
    closeButton.setAttribute('aria-expanded', 'false');
    textElement.textContent = 'Copy link';
  });
}
