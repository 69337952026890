// NOTE The elements for this module to be inside the Vue instance because they both contain the
// header-search vue component. If this proves problematic then this module may need to be
// refactored to use Vue event handling instead of native.

export default function init() {
  // ============================== Desktop menu

  // The container itself is the trigger in this case, not using a seperate element like mobile
  // because of the hover effect which has to be applied to the whole container
  document.querySelectorAll<HTMLElement>('.js-mega-nav').forEach((dropdown) => {
    const trigger = dropdown.querySelector<HTMLButtonElement>('.js-mega-nav-trigger')!;

    // Show / hide menu on enter key
    trigger.addEventListener('keydown', (e) => {
      if (e.key === 'Enter' || e.keyCode === 13) {
        dropdown.classList.toggle('open');
        trigger.ariaExpanded = dropdown.classList.contains('open') ? 'true' : 'false';
      }
    });

    // Show menu on mouse over
    dropdown.addEventListener('mouseover', () => {
      dropdown.classList.add('open');
      trigger.ariaExpanded = 'true';
    });

    // Hide menu on mouse leave
    dropdown.addEventListener('mouseleave', () => {
      dropdown.classList.remove('open');
      trigger.ariaExpanded = 'false';
    });
  });

  // ============================== Mobile menu

  // ====== Show/hide mobile menu
  const mobileTrigger = document.querySelector<HTMLButtonElement>('.js-mobile-menu-trigger')!;
  const mobileMenu = document.getElementById(mobileTrigger.getAttribute('aria-controls')!)!;
  mobileTrigger.addEventListener('click', () => {
    mobileTrigger.classList.toggle('open');
    const isOpen = mobileTrigger.classList.contains('open');
    mobileTrigger.ariaExpanded = isOpen ? 'true' : 'false';
    mobileMenu.classList.toggle('hidden');

    const header = document.querySelector('#js-header')!;
    header.classList.toggle('is-stuck', isOpen && isStuck(header));
  });

  // ====== Show/hide mobile nav dropdowns
  document.querySelectorAll('.js-mobile-dropdown').forEach((dropdown) => {
    const trigger = dropdown.querySelector<HTMLButtonElement>('.js-mobile-dropdown-trigger')!;

    // Show / hide menu on click
    trigger.addEventListener('click', () => {
      dropdown.classList.toggle('open');
      trigger.ariaExpanded = dropdown.classList.contains('open') ? 'true' : 'false';
    });
  });
}

function isInNormalFlow(el: Element) {
  const { position } = window.getComputedStyle(el);

  return ['static', 'relative', 'sticky'].includes(position);
}

function isStuck(el: Element) {
  // Get the height between the top of the element and the top of the parent
  // NOTE This accounts for block elements and position: absolute, but isn't suitable for more
  // complex layouts eg. order: -1
  let heightAboveEl = 0;
  let previousSibling = el.previousElementSibling;

  while (previousSibling) {
    if (isInNormalFlow(previousSibling)) {
      const siblingHeight = previousSibling.getBoundingClientRect().height;
      heightAboveEl += siblingHeight;
    }
    previousSibling = previousSibling.previousElementSibling;
  }

  const elTop = el.getBoundingClientRect().top;
  const parentTop = el.parentElement!.getBoundingClientRect().top + heightAboveEl;

  return elTop > parentTop;
}
