// Vue instances
import Header from '@vue-instances/Header';

// Modules
import megaMenu from '@modules/megaMenu';

export default function init() {
  Header();
  megaMenu();
}
