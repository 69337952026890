export default function init() {
  document.querySelectorAll<HTMLButtonElement>('.js-accordion-btn').forEach((trigger) => {
    const content = document.getElementById(trigger.getAttribute('aria-controls')!)!;

    trigger.addEventListener('click', () => {
      content.classList.toggle('hidden');
      trigger.classList.toggle('open');

      if (trigger.classList.contains('open')) {
        trigger.ariaExpanded = 'true';
        content.ariaHidden = 'false';
      } else {
        trigger.ariaExpanded = 'false';
        content.ariaHidden = 'true';
      }
    });
  });
}
