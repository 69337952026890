<script lang="ts">
import { defineComponent } from 'vue';
import algoliasearch from 'algoliasearch/lite';
import clickOutside from '../directives/clickOutside';

import {
  AisHits,
  AisInstantSearch,
  AisSearchBox,
  AisStateResults,
  AisStats,
} from 'vue-instantsearch/vue3/es';

export default defineComponent({
  components: {
    AisHits,
    AisInstantSearch,
    AisSearchBox,
    AisStateResults,
    AisStats,
  },
  directives: {
    clickOutside,
  },
  props: {
    searchApiKey: {
      type: String,
      required: true,
    },
    appId: {
      type: String,
      required: true,
    },
    environment: {
      type: String,
      required: true,
    },
    isDesktop: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isEnabled: false,
      searchClient: algoliasearch(this.appId, this.searchApiKey),
      searchQuery: '',
    };
  },
  computed: {
    indexName() {
      return `${this.environment}_headerSearch`;
    },
  },
  watch: {
    /**
     * Open / close search if device width/orientation changes
     */
    isDesktop: {
      immediate: true,
      handler(isDesktop: boolean) {
        this.isEnabled = !isDesktop;
      },
    },
  },
  methods: {
    clickOutside() {
      if (this.isDesktop) {
        this.isEnabled = false;
      }
    },
    focusSearchInput() {
      const { searchBox } = this.$refs;
      const { searchInput } = searchBox.$refs;
      const { input } = searchInput.$refs as { input: HTMLInputElement };
      // Focus the search input (using autofocus is only reliable on page load)
      input.focus();
    },
  },
});
</script>
<template>
  <div v-click-outside.stop="clickOutside" class="relative lg:flex lg:items-center">
    <button class="hidden lg:block" aria-label="Open search bar" @click="isEnabled = true">
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
      >
        <g>
          <circle
            cx="8.60864"
            cy="8.93118"
            r="8.10487"
            transform="rotate(-0.0250705 8.60864 8.93118)"
            stroke="#101820"
          />
          <line x1="14.2891" y1="14.0332" x2="18.3867" y2="18.1307" stroke="#101820" />
        </g>
      </svg>
    </button>
    <Transition @after-enter="focusSearchInput">
      <div
        v-if="isEnabled"
        class="shadow-default z-50 rounded-2xl bg-jordans-off-white focus-within:ring-2 focus-within:ring-jordans-autumn-orange lg:absolute lg:-right-3 lg:top-0 [&.v-enter-active]:transition-opacity [&.v-enter-from]:opacity-0 [&.v-leave-active]:transition-opacity [&.v-leave-to]:opacity-0"
      >
        <ais-instant-search :search-client="searchClient" :index-name="indexName">
          <ais-search-box
            ref="searchBox"
            v-model="searchQuery"
            show-loading-indicator
            placeholder="Search..."
            :class-names="{
              'ais-SearchBox-form': 'relative',
              'ais-SearchBox-input': `bg-transparent pl-4 pr-16 py-3 border-0 w-full focus:outline-none focus:ring-0 lg:w-auto lg:px-4 [--foo:2]`,
              'ais-SearchBox-loadingIndicator': 'hidden',
              'ais-SearchBox-reset': 'hidden',
              'ais-SearchBox-submit': '-translate-y-1/2 absolute right-3 top-1/2',
            }"
            @keydown.esc="isEnabled = false"
          >
            <template #submit-icon>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                @click.prevent="focusSearchInput"
              >
                <g stroke="#101820">
                  <circle
                    cx="8.60864"
                    cy="8.93118"
                    r="8.10487"
                    transform="rotate(-0.0250705 8.60864 8.93118)"
                  />
                  <line x1="14.2891" y1="14.0332" x2="18.3867" y2="18.1307" />
                </g>
              </svg>
            </template>
          </ais-search-box>
          <ais-stats>
            <template #default="{ nbHits, query }">
              <section v-if="nbHits > 0 && query" class="max-h-80 overflow-scroll px-4 pb-2">
                <ais-state-results>
                  <ais-hits>
                    <template #default="{ items /* , sendEvent, query */ }">
                      <ul class="flex flex-col justify-start divide-y border-t">
                        <li v-for="item in items" :key="item.objectID">
                          <a
                            :href="item.url"
                            class="block py-2 text-sm font-normal hover:underline"
                          >
                            {{ item.title }}
                          </a>
                        </li>
                      </ul>
                    </template>
                  </ais-hits>
                </ais-state-results>
              </section>
              <section v-else-if="nbHits === 0 && query.length > 0" class="px-4 pb-2">
                <p class="border-t py-2 text-sm">No results have been found for {{ query }}.</p>
              </section>
              <!-- Blank for the else as we want no output -->
              <div v-else></div>
            </template>
          </ais-stats>
        </ais-instant-search>
      </div>
    </Transition>
  </div>
</template>
