import type { NonEmptyArray, ConsentCategory } from '../types/cookiebot';

/**
 * Promise wrapper for all given cookie categories to be consented
 */
export function allConsentGiven(categories: NonEmptyArray<ConsentCategory>) {
  return new Promise<void>((resolve /* , reject */) => {
    if (checkConsent(categories)) {
      resolve();
    }

    window.addEventListener('CookiebotOnAccept', () => {
      if (checkConsent(categories)) {
        resolve();
      }
    });
  });
}

/**
 * Utility for checking if all consent categories have been given
 */
export function checkConsent(categories: NonEmptyArray<ConsentCategory>) {
  if (!categories.length) {
    throw new Error('No categories provided');
  }

  if (!window.Cookiebot || window.Cookiebot instanceof HTMLElement) {
    return false;
  }

  for (const category of categories) {
    if (window.Cookiebot.consent[category] !== true) {
      return false;
    }
  }

  return true;
}
