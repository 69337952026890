export default function init() {
  document.querySelectorAll<HTMLDivElement>('.js-video-container').forEach((container) => {
    const playBtn = container.querySelector<HTMLElement>('.js-play-btn')!;

    playBtn.addEventListener('click', () => {
      const iframe = container.querySelector('iframe')!;
      const facadeImg = container.querySelector<HTMLImageElement>('.js-facade-img')!;
      const embed = container.querySelector<HTMLDivElement>('.js-video-embed')!;

      playBtn.classList.add('hidden');
      facadeImg.classList.add('hidden');
      embed.classList.remove('hidden');
      iframe.setAttribute('src', iframe.dataset.src!);
      playBtn.style.display = 'none';
    });
  });
}
