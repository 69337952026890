import fetchData from '@helpers/fetchData';
import { elementsInViewOnce } from '@helpers/elementsInView';
import { allConsentGiven, checkConsent } from '@helpers/cookiebot';
import type Mapboxgl from 'mapbox-gl';
import type { NonEmptyArray, ConsentCategory } from '../types/cookiebot';

type LatLng = [number, number];

/**
 * To extend this type examine a Mapbox API response or use the playground:
 * https://docs.mapbox.com/playground/geocoding-v5/
 */
type GeoData = {
  query: LatLng | string[];
  features: NonEmptyArray<{
    center: LatLng;
  }>;
};

const accessToken =
  'pk.eyJ1Ijoiam9yZGFuc3NvbGljaXRvcnMiLCJhIjoiY2x1czl6dzI3MGptbTJpdWpwd3NmMTkyeSJ9.5FAU_5K8YOpdnPjMNONYaQ';
const mapboxStyle = 'mapbox://styles/jordanssolicitors/clxbuwgds025r01pc13v219ss';
const markerColour = '#E8751E';
const defaultZoom = 6;
const consentCategories: NonEmptyArray<ConsentCategory> = ['marketing', 'preferences'];
let geoDataPromise: Promise<GeoData> | null = null;

/**
 * Import mapbox-gl dependencies once when the map is in view with a 250px buffer
 */
const importDepenciesOnceWhenInView = elementsInViewOnce(
  () => Promise.all([import('mapbox-gl'), import('mapbox-gl/dist/mapbox-gl.css')]),
  250,
);

export default async function init() {
  const mapContainer = document.querySelector<HTMLDivElement>('.js-map');
  if (!mapContainer) return;

  // Wait for consent to be given
  await allConsentGiven(consentCategories);

  // Wait for dependencies to be imported
  const [{ default: mapboxgl }] = await importDepenciesOnceWhenInView([mapContainer]);
  mapboxgl.accessToken = accessToken;

  // Create map
  let map: Mapboxgl.Map | null = await initializeMap(mapboxgl, mapContainer);

  // Cleanup if consent is withdrawn
  window.addEventListener('CookiebotOnDecline', () => {
    if (!checkConsent(consentCategories)) {
      map?.remove();
      map = null;
    }
  });

  // Re-initialize map if consent is given
  window.addEventListener('CookiebotOnAccept', async () => {
    if (checkConsent(consentCategories)) {
      map = await initializeMap(mapboxgl, mapContainer);
    }
  });
}

/**
 * Initialize the map
 */
const initializeMap = async (mapboxgl: typeof Mapboxgl, container: HTMLDivElement) => {
  const latLng = await getLatLng(container);

  const zoom = 'zoomlevel' in container.dataset ? Number(container.dataset.zoomlevel) : defaultZoom;
  const map = new mapboxgl.Map({
    container,
    style: mapboxStyle,
    center: latLng,
    zoom,
    scrollZoom: false,
  });
  map.addControl(new mapboxgl.NavigationControl());

  new mapboxgl.Marker({ color: markerColour }).setLngLat(latLng).addTo(map);

  return map;
};

/**
 * Get the lat & lng from the map container as either from its data attributes if available, or by
 * looking up the location name using the Mapbox geocoding API
 */
const getLatLng = async (container: HTMLElement): Promise<LatLng> => {
  const lng = 'lng' in container.dataset ? Number(container.dataset.lng) : undefined;
  const lat = 'lat' in container.dataset ? Number(container.dataset.lat) : undefined;

  if (lng && lat) {
    return [lng, lat];
  }

  // Lookup using marker container
  const geoData = await fetchGeoData(container);
  return geoData.features[0].center;
};

/**
 * Fetch geodata from Mapbox
 */
const fetchGeoData = async (container: HTMLElement) => {
  // Cache from previous initialisation, in case cookie consent is withdrawn then given again
  if (!geoDataPromise) {
    const locationTitle = container.dataset.location;
    if (!locationTitle) {
      console.error('No location or coordinates provided', container);
      throw new Error('No location or coordinates provided');
    }

    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(locationTitle)}.json?access_token=${encodeURIComponent(accessToken)}`;
    geoDataPromise = fetchData<GeoData>(url)
      .then((data) => {
        if (!data || !data.features?.length || !data.query) {
          throw new Error('Invalid geodata received');
        }

        return data;
      })
      .catch((error) => {
        console.error(`Error fetching geodata`, error);
        throw error; // Re-throw the error to be caught in initializeMap
      });
  }

  return geoDataPromise;
};
